export enum FileSizes {
	FiveMB = 1024 * 1024 * 5,
	TenMB = 1024 * 1024 * 10,
	TwentyMB = 1024 * 1024 * 20,
	HundredAndTwentyMB = 1024 * 1024 * 120,
}

export enum Languages {
	TR = 'tr',
	EN = 'en',
}

export enum SocialAccount {
	Linkedin = 'linkedin',
	Github = 'github',
	Google = 'google',
}

export enum UISize {
	Sm = 'sm',
	Md = 'md',
	Lg = 'lg',
	Xl = 'xl'
}

export enum State {
	Published = 'published',
	Draft = 'draft',
	Closed = 'closed',
	UnPublished = 'unpublished',
	Pending = 'pending',
	Active = 'active',
	Passive = 'passive',
	Deleted = 'deleted',
	Declined = 'declined',
}

export enum UIState {
	Active = 'active',
	Warning = 'warning',
	Error = 'error',
	Success = 'success',
	Info = 'info',
	Default = 'default',
	Danger = 'danger',
	Idle = 'idle',
	UserProfile = 'userProfile',
	WorkPreferences = "WorkPreferences",
}

export enum PriceSymbols {
	TL = '₺',
	USD = '$',
}

export enum CurrencySymbols {
	TRY = '₺',
	USD = '$',
}

export enum FileExtensionTypes {
	PDF = '.pdf',
	DOCX = '.docx',
}

export enum LogoVariants {
	Company = 'company',
	Talent = 'talent',
	Blok = 'blok',
}

export enum LogoSizes {
	XL = 'xl',
	L = 'l',
	MD = 'md',
	MD2 = 'md2',
	SM = 'sm',
	SM2 = 'sm2',
	XS = 'xs',
}

export enum SocialLinks {
	DWSPlus = 'https://blog.digitalwisers.com/',
	Instagram = 'https://www.instagram.com/digitalwisers/',
	LinkedIn = 'https://www.linkedin.com/company/digitalwisers/',
}

export enum PageTranslation {
	TR = 'tr',
	EN = 'en',
}

export enum MessageSenderTypes {
	Blok = 'blok',
	Company = 'company',
	Talent = 'talent',
}

export enum SupportMail {
	Support = 'info@digitalwisers.com',
}

export enum ScreenModes {
	Mobile = 'mobile',
	Tablet = 'tablet',
	Desktop = 'desktop',
}

export enum LandingSubmitTypes {
	Question = 'question',
	Demo = 'demo',
}

export enum AccountTypes {
	Talent = 'talent',
	CompanyOwner = 'companyOwner',
	BlokOwner = 'blokOwner',
	Staff = 'staff',
}

export enum DwsScoreVariants {
	Small = 'small',
	Large = 'large',
}

export enum MailStatus {
	Verified = 'verified',
}

export enum ReferralTypes {
	Talent = 'talent',
	Blok = 'blok',
	Company = 'company',
}

export enum OnboardingFlowTypes {
	Event = 'event',
}

export enum SubscriptionPackageTypes {
	Networker = 'networker',
	CommunityManager = 'communityManager',
	Enterprise = 'enterprise',
}

export enum PackageSlugs {
	PublishJobPost = 'publish-job-post',
	BoostJobPost = 'boost-job-post',
	AccessCandidatePool = 'access-candidate-pool',
	RequestReferral = 'request-referral',
}

export enum SocialMediaProviderTypes {
	Google = 'google',
	LinkedIn = 'linkedin',
}

export enum BlogPostCategory {
	ForCompanies = 'forCompanies',
	ForTalents = 'forTalents',
	ForBlokOwners = 'forBlokOwners',
}

export enum ServiceCategory {
	ForServices = 'forServices',
	ForProducts = 'forProducts',
	ForEducation = 'forEducation',
	ForConsulting = 'forConsulting',
	ForSponsorship = 'forSponsorship',
	ForMembership = 'forMembership',
	ForBlokPaywall = 'forBlokPaywall',
	ForOther = 'forOther',
}

export enum Environments {
	Development = 'development',
	Staging = 'staging',
	Production = 'production',
}

export enum DwsStatus {
	Negative = 'negative',
	Interviewing = 'interviewing',
	NegativeAfterInterview = 'negative-after-interview',
	Offer = 'offer',
	OfferRejected = 'offer-rejected',
	Hired = 'hired',
}

export enum KeyboardKeys {
	Enter = 'Enter',
}

export enum LocationTypes {
	Remote = 'Remote',
	Office = 'Office',
	Hybrid = 'Hybrid'
}

export enum JobTypes {
	FullTime = 'Full time',
	PartTime = 'Part time',
	Intern = 'Intern',
	ProjectBased = 'Project Based',
}

export enum TalentInterests {
	ApplyToJobs = "apply-to-jobs",
	GetInfoFromCommunities = "get-info-from-communities",
	ExpandNetwork = "expand-network",
	GetHelpFromCommunityLeaders = "get-help-from-community-leaders",
}

export enum CompanyInterests {
	GetTalentSuggestionFromBlokOwners = "get-talent-suggestion-from-blok-owners",
	PublishJobs = "publish-jobs",
	SortJobApplicationsByDwsScore = "sort-job-applications-by-dws-score",
	BuyService = "buy-service",
	GetInfoFromCommunities = "get-info-from-communities",
}

export enum BlokOwnerInterests {
	SupportCommunityCareerGrowth = "support-community-career-growth",
	GrowCommunity = "grow-community",
	SellService = "sell-service",
	ReferTalentToJobs = "refer-talent-to-jobs",
	ShareConent = "share-content",
	CreateEvent = "create-event",
}

export enum BlokNetworkReach {
	OneK = '1.000',
	OneToFiveK = '1.000-5.000',
	FiveToTenK = '5.000-10.000',
	TenToFiftyK = '10.000-50.000',
	FiftyToFiveHundredK = '50.000-500.000',
	FiveHundredToOneM = '500.000-1.000.000',
	OneMPlus = '1.000.000+',
}


export enum BlokOwnerServices {
	Training = "training",
	Consultancy = "consultancy",
	TechnicalAssessment = "technical-assessment",
	FindTalents = "find-talents",
	Sponsorship = "sponsorship",
	JobBoost = "job-boost",
	NotInterested = "not-interested-in-service-sale"
}

export enum PostOwnerTypes {
	Blok = 'blok',
}

export enum PrivacySetting {
  Public = 'Public',
  MembersOnly = 'MembersOnly'
}

export enum BlokOwnerTypes {
  IndividualCommunityLeader = 'IndividualCommunityLeader',
  NGO = 'NGO',
  EducationalInstitution = 'EducationalInstitution',
  HumanResourcesAgency = 'HumanResourcesAgency',
  CareerCenter = 'CareerCenter',
  CoachingMentoringCompany = 'CoachingMentoringCompany',
  Incubator = 'Incubator',
  Corporate = 'Corporate',
  CoworkingSpace = 'CoworkingSpace',
  ChamberofCommerceAssociation = 'ChamberofCommerceAssociation',
  StudentOrganization = 'StudentOrganization',
  SciencePark = 'SciencePark',
  VentureCapital = 'VentureCapital',
  Other = 'Other'
}

export enum BlokCategories {
  AllBloks = 'AllBloks',
  Featured = 'Featured',
  Business = 'Business',
  SoftwareIT = 'SoftwareIT',
  SalesMarketing = 'SalesMarketing',
  HR = 'HR',
  Design = 'Design',
  Finance = 'Finance',
  DataAnalytics = 'DataAnalytics',
  OtherCategories = 'OtherCategories'
}

export enum OrderOptions {
  Alphabetical = 'alphabetical',
  RecentToOld = 'recent',
  Points = 'points'
}

export enum GenderOptions {
  Man = 'man',
  Woman = 'woman'
}

export enum ForumCategories {
	Announcement = 'Announcement',
	QuestionDiscussion = 'QuestionDiscussion',
	Support = 'Support',
	General = 'General',
}

export enum ForumStatus {
	Active = 'active',
	Canceled = 'canceled',
}
