import dayjs from "dayjs";
import { Trans } from "react-i18next";

interface IDateDiff {
  date: string;
  short?: boolean;
}

const DateDiff = (props: IDateDiff) => {
  const getDiff = (date: string, type: dayjs.UnitType) => {
    const timezone = dayjs.tz.guess()
    return dayjs(dayjs().tz(timezone)).tz(timezone).diff(date, type)
  }

  const getDateDiff = () => {
    const days = getDiff(props.date, 'day');
    const hours = getDiff(props.date, 'hour');
    const minutes = getDiff(props.date, 'minute');
    const months = getDiff(props.date, 'month');
    const weeks = Math.floor(days / 7);

    if (days >= 30) {
      return { key: "DateDiff.Months", shortKey: "DateDiff.Short.Months", value: months };
    } else if (days >= 7) {
      return { key: "DateDiff.Weeks", shortKey: "DateDiff.Short.Weeks", value: weeks };
    } else if (days > 0) {
      return { key: "DateDiff.Day", shortKey: "DateDiff.Short.Day", value: days };
    } else if (hours > 0) {
      return { key: "DateDiff.Hours", shortKey: "DateDiff.Short.Hours", value: hours };
    }

    return { key: "DateDiff.Minutes", shortKey: "DateDiff.Short.Minutes", value: minutes };
  }

  const diff = getDateDiff();

  return (
    <Trans
      i18nKey={props.short ? diff.shortKey : diff.key}
      values={{
        value: diff.value > 0 ? diff.value : 0,
      }}
    />
  )
};

export default DateDiff