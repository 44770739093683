import React from 'react';
import { __ } from '@/utils/Trans';
import blokStore from '@/js/modules/bloks/store/BlokStore';
import BloksTable from '@/js/modules/bloks/components/BloksTable';
import Skeleton from 'react-loading-skeleton';
import { useQuery } from '@tanstack/react-query';
import { Tab } from '@headlessui/react';
import clsx from 'clsx';

interface IBloksPage { }

export enum Tabs {
	Approved = 'approved',
	Pending = 'pending',
	Invites = 'invites'
}

export default function BloksPage(props: IBloksPage) {
	blokStore.initializeStore();

	const initialCols = [
		{ name: __("BloksPage.Columns.Bloks"), classes: "" },
		{ name: __("BloksPage.Columns.Description"), classes: "" }
	]

	const [selectedTab, setSelectedTab] = React.useState<Tabs>(Tabs.Approved);
	const [columns, setColumns] = React.useState<typeof initialCols>(initialCols);

	const { data, isLoading } = useQuery({
		queryKey: ['joinedBloks', selectedTab],
		queryFn: async () => {
			await blokStore.fetchJoinedBloks()
			return blokStore.getFilteredBloksBy(selectedTab);
		},
	})

	const setTab = (value: Tabs) => {
		setSelectedTab(value);

		setColumns([
			{ name: __("BloksPage.Columns.Bloks"), classes: "" },
			{ name: __("BloksPage.Columns.Description"), classes: "" },
			{ name: "", classes: "" }
		]);

		if (blokStore.isTalentAccountType() && value === Tabs.Approved) {
			setColumns([
				{ name: __("BloksPage.Columns.Bloks"), classes: "" },
				{ name: __("BloksPage.Points"), classes: 'text-center' },
				{ name: __("BloksPage.Columns.Description"), classes: "" },
				{ name: "", classes: "" }
			])
		}
		
		if (blokStore.isTalentAccountType() && value === Tabs.Invites) {
			setColumns([
				{ name: __("BloksPage.Columns.Bloks"), classes: "" },
				{ name: __("BloksPage.Columns.Description"), classes: "" },
				{ name: "", classes: "" }
			])
		}

		if (blokStore.isCompanyOwnerAccountType()) {
			setColumns([
				{ name: __("BloksPage.Columns.Bloks"), classes: "" },
				{ name: __("BloksPage.Columns.ApprovedAt"), classes: "" },
				{ name: __("BloksPage.Columns.JobPostCount"), classes: "" },
				{ name: '', classes: "" },
				{ name: '', classes: "" },
			]);
		}
	}

	React.useEffect(() => {
		setTab(selectedTab);
	}, [data])

	const tabs = [
		{ name: __("BloksPage.Approved"), value: Tabs.Approved, condition: true },
		{ name: __("BloksPage.Pending"), value: Tabs.Pending, condition: true, },
		{ name: __("BloksPage.Invites"), value: Tabs.Invites, condition: blokStore.isTalentAccountType() }
	]

	return (
		<div className={'bg-neutral-50 flex flex-col w-full min-h-screen'}>
			<Tab.Group
				as={"div"}
				defaultIndex={0}
				onChange={(index) => setTab(tabs[index].value)}
				className={"mt-8 mx-0 md:mx-[30px] py-6 pl-5 px-0 md:px-[30px] bg-white md:rounded-2xl border-2 border-shade-stroke mb-48"}
			>
				{
					isLoading
						? <Skeleton count={3} />
						: selectedTab && (
							<>
								<h1 className={"text-shade-black text-header-bold-h8 mb-6"}>
									{__("BloksPage.EmptyCardTitle")}
								</h1>
								<Tab.List className={'flex gap-3 items-center'}>
									{tabs.map((tab) => (
										tab.condition && (
											<Tab
												key={tab.value}
												value={tab.value}
												className={({ selected }) => clsx(
													'text-neutral-500 px-3 py-[9px] ring-0 outline-none rounded-full border border-shade-stroke text-label-regular-l8',
													{
														'bg-primary-500 text-white !text-label-semibold-l8 !border-0': selected
													}
												)}
											>
												{__(tab.name)}
											</Tab>
										)
									))}
								</Tab.List>
								<Tab.Panels
									as='div'
									className="max-h-max overflow-hidden mt-4"
								>
									<div className="w-full overflow-x-auto overflow-y-hidden">
										<BloksTable
										columns={columns}
										bloks={data || []}
										selectedTab={selectedTab}
										/>
									</div>
								</Tab.Panels>
							</>
						)
				}
			</Tab.Group >
		</div>
	)
}
