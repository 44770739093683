import { useNavigate, useSearchParams } from "react-router-dom";
import React from 'react'
import { track } from "@/plugins/amplitude";
import { TrackerEvents } from "@/utils/TrackerEvents";
import userStore from "@/js/modules/users/store/v2/UserStore";
import LocalStorage from "@/js/helpers/localStorage";
import { __ } from "@/utils/Trans";
import talentStore from "@/js/modules/talent/store/TalentStore";
import { useTranslation } from "react-i18next";
import { VerificationErrors } from "@/js/modules/auth/constants/VerificationErrors";
import { resetOnboardingLocalStorage } from "@/js/modules/onboarding/constants/onbardingConstants";
import { PageRoutes } from "@/utils/PageRoutes";
import { toast } from "react-hot-toast";

export default function OnboardingLayout() {
	talentStore.initializeStore()

	const { i18n } = useTranslation()
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

  const updateLanguage = async () => {
    await userStore.updateLanguage({ language: i18n.language });
  };

	const handleTokenVerification = async (token: string) => {
    resetOnboardingLocalStorage();
    track(TrackerEvents.VerifyAccount, { token });
		const verfiyUser = await userStore.verify(token);

    const isVerified = (verfiyUser) || userStore.state.errors.token === VerificationErrors.emailAlreadyVerified;

    if (isVerified && verfiyUser) {
			toast.success(__('OnboardingPage.EmailVerified'))

			LocalStorage.set("accessToken", token);

			const user = await userStore.fetchMe();
			LocalStorage.set("user", JSON.stringify(user));

			await talentStore.createTalentAccount({});
			const redirect = searchParams.get('redirect') || PageRoutes.UserFeedPage;
			navigate(redirect);
    } else {
			const redirect = searchParams.get('redirect') || PageRoutes.WelcomePage;
			navigate(redirect);
		}
  };

  React.useEffect(() => {
    const init = async () => {
      const token = searchParams.get('token');
      if (token) {
        await handleTokenVerification(String(token));
      }
			await updateLanguage();
			navigate(PageRoutes.WelcomePage);
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


	return (
		<>
		</>
	);
}
