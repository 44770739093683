import { __ } from '@/utils/Trans';
import React from 'react';
import { Link } from 'react-router-dom';
import FormLogo from '@/js/modules/onboarding/components/company/FormLogo';
import { LogoSizes } from '@/utils/Enums';
import DateDiff from '@/js/components/DateDiff/DateDiff';
import { INotification } from '@/js/modules/notifications/store/NotificationTypes';
import notificationStore from '@/js/modules/notifications/store/NotificationStore';

interface INotificationCard extends React.PropsWithChildren {
	classes?: string;
	item: INotification;
}

const NotificationCard = ({ item, classes }: INotificationCard) => {
	const getNotificationText = (type, message) => {
		switch (type) {
			case 'EventPublish':
				return `${__('Notifications.EventCreated')} <strong>${message}</strong>`;
			case 'EventUpdate':
				return `${__('Notifications.EventUpdated')} <strong>${message}</strong>`;
			case 'EventCancel':
				return `❌<strong>${message}</strong> ${__('Notifications.EventCancelled')}`;
			case 'EventReminder':
				return `<strong>${message}</strong> ${__('Notifications.EventReminder')}`;
			default:
				console.log ('Notification not found');
		}
	};

	const getNotificationEmoji = (type) => {
		if (type.includes('Event')) {
			return '📅';
		}
		return '';
	};

	const handleNotificationClick = async () => {
		try {
			await notificationStore.updateNotificationToRead(item.id);
		} catch (error) {
			console.error('Error updating notification:', error);
		}
	};

	return (
		<Link to={item.redirectUrl} key={item.id} className={classes} onClick={handleNotificationClick} >
			<div className="flex justify-between py-4 px-2.5 bg-white hover:bg-shade-background">
				<div className="flex-col">
					<FormLogo
						size={LogoSizes.XS}
						logoUrl={item?.image || ''}
						variant={item?.ownerAccountType}
						inputName={'logo'}
						classes="cursor-pointer"
					/>
				</div>
				<div className="flex-col gap-1 ml-3">
					<p className="text-shade-black text-sm font-semibold">{item?.ownerName}</p>
					<div
						className="text-shade-black text-xs line-clamp-2"
						dangerouslySetInnerHTML={{
							__html: `${getNotificationEmoji(item?.type)} ${getNotificationText(
								item?.type,
								item?.message
							)}`,
						}}
					/>
				</div>
				<div className="flex items-start gap-2">
					<div className="text-shade-black text-[11px]">
						<DateDiff date={String(item?.created)} short={true} />
                    </div>
                    {item?.isRead ? (
                        <div className="w-[13px] h-[13px] bg-neutral-200 rounded-full"></div>
                    ) : (
                        <div className="w-[13px] h-[13px] bg-primary-500 rounded-full"></div>
                    )}
				</div>
			</div>
		</Link>
	);
};

export default NotificationCard;
