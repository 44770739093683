import BaseService from "@/js/core/BaseService";
import { NotificationEndpoints } from "../store/NotificationEndpoints";

export default class NotificationService extends BaseService {

	async HasNotifications() {
		return await this.api().get(NotificationEndpoints.HasNotifications)
	}
	async FetchNotifications() {
		return await this.api().get(NotificationEndpoints.FetchNotifications)
	}
	async UpdateNotificationToRead(id: string) {
		return await this.api().put(NotificationEndpoints.UpdateNotificationToRead.replace(':id', id))
	}
	async UpdateNotificationsToRead() {
		return await this.api().put(NotificationEndpoints.UpdateNotificationsToRead)
	}
}
