import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import ArrowDownIcon from '@/assets/icons/ArrowDownIcon'
import { LookingForJobTypes } from '@/js/modules/talent/store/TalentTypes'
import { __ } from '@/utils/Trans'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

interface ILookingForJobTypes {
  icon: JSX.Element;
  title: string;
  desription: string;
  value: LookingForJobTypes;
}

interface ISelectMenu {
  label?: string;
  data?: any[];
  icon?: any;
  value?: any;
  placeholder?: string;
  onChange(value: any): void;
}

function SelectMenu(props: ISelectMenu) {
  return (
    <Listbox value={props.value} onChange={(value: any) => props.onChange(value)}>
      {({ open }) => (
        <>
          <Listbox.Label className={'flex items-center gap-1 mb-2 text-shade-black text-body-semibold-b5 dark:text-gray-400'}>
            {props.label}
          </Listbox.Label>
          <div className='relative w-full group'>
            <Listbox.Button className='inline-flex w-full h-[48px] border rounded-lg border-shade-stroke dark:border-gray-400 shadow-none px-4 py-2 dark:bg-transparent'>
              <div className='inline-flex items-center gap-x-1.5 bg-transparent py-2  text-gray-700  w-full '>
                <p className='text-sm text-gray-700 flex gap-2 items-center'>
                  {props.value?.icon}
                  {__(props.value?.title)}
                </p>
              </div>
              <Listbox.Button className='inline-flex items-center bg-transparent p-2 focus:outline-none'>
                <span className='text-gray-200 group-hover:text-gray-400'><ArrowDownIcon /></span>
              </Listbox.Button>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options className='absolute right-0 z-10 mt-2 w-full origin-top-right divide-y divide-gray-200 overflow-hidden rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                {props.data?.map((option) => (
                  <Listbox.Option
                    key={option.title}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-gray-50 text-white' : 'text-gray-700',
                        'cursor-pointer select-none p-4 text-sm',
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div className={`flex flex-col`}>
                        <div className='flex justify-between flex-col gap-2'>
                          <div className='flex items-center gap-2'>
                            {option.icon}
                            <p className={`${selected ? 'font-semibold' : 'font-normal'} text-sm text-gray-700`}>
                              {__(option.title)}
                            </p>
                          </div>
                          {option.description ?
                            <p className='font-medium text-xs text-gray-400'>
                              {__(option.description)}
                            </p>
                            : ''}
                        </div>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

export default SelectMenu
