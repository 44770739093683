import React from 'react';
import FormLogo from '@/js/modules/onboarding/components/company/FormLogo';
import { AccountTypes, LogoSizes, LogoVariants, UIState } from '@/utils/Enums';
import Badge from '@/js/components/Badge/Badge';
import { Link, useNavigate } from 'react-router-dom';
import { __ } from '@/utils/Trans';
import { PageRoutes } from '@/utils/PageRoutes';
import MoreAction from '@/js/components/MoreAction/MoreAction';
import { RowStates } from '@/js/core/enums/RowStates';
import blokStore from '@/js/modules/bloks/store/BlokStore';
import DateDiff from '@/js/components/DateDiff/DateDiff';
import { Trans } from 'react-i18next';
import { Icons } from '@/utils/Icons';
import Icon from '@/js/components/Icon/Icon';
import DeleteRequestCompanyFromBlokModal from '@/js/modules/bloks/components/DeleteRequestCompanyFromBlokModal';
import DeleteRequestTalentFromBlokModal from '@/js/modules/bloks/components/DeleteRequestTalentFromBlokModal';
import RemoveTalentModal from '@/js/modules/bloks/components/RemoveTalentModal';
import UnsubscribeFromBlokModal from '@/js/modules/bloks/components/UnsubscribeFromBlokModal';
import blokPublicStore from "@/js/modules/bloks/store/BlokPublicStore.ts";
import { useQueryClient } from '@tanstack/react-query';
import { Tabs } from '../pages/BloksPage';
import CrossIcon from '@/assets/icons/CrossIcon';
import ReplyIcon from '@/assets/icons/ReplyIcon';
import Button from '@/js/components/Buttons/Button';
import { useWindowSize } from '@/js/hooks/useWindowSize/useWindowSize';
import { ScreenSizes } from '@/utils/ScreenSizes';
import StarIconV3 from '@/assets/icons/StarIconV3/StarIconV3';
import BaseTableV2 from '@/js/components/BaseTable/BaseTableV2';

interface IBloksTable {
	bloks: [];
	selectedTab: Tabs;
	columns: { name: string, classes?: string }[];
}
export interface IBlokData {
	advertPrice: number;
	id: string;
	slug: string;
	name: string;
	description: string;
	avatarUrl: string;
	price: number;
	applicationType?: RowStates | string;
	isGlobal: boolean;
	isBlokOwnerVisible: boolean;
	state: RowStates | string;
	user: { firstName: string, lastName: string, username: string };
	locations: { label: string, value: string }[];
	socialAccounts: { iconUrl: string, url: string }[];
	isOwner: boolean;
	skills: { label: string, value: string }[];
	sectors: { label: string, value: string }[];
	created: string;
	jobPostCount: number;
	blokOwnerFullName?: string;
	can_post_job?: boolean;
	userTotalPoint: number;
}

export default function BloksTable(props: IBloksTable) {
	const [selectedBlok, setSelectedBlok] = React.useState<IBlokData | null>(null);
	const [isRemoveTalentModalOpen, setIsRemoveTalentModalOpen] = React.useState(false);
	const [isUnsubscribeFromBlokModalOpen, setIsUnsubscribeFromBlokModalOpen] = React.useState(false);
	const [isDeleteRequestCompanyModalOpen, setIsDeleteRequestCompanyModalOpen] = React.useState(false);
	const [isDeleteRequestTalentFromBlokModalOpen, setIsDeleteRequestTalentFromBlokModalOpen] = React.useState(false);

	const navigate = useNavigate()
	const queryClient = useQueryClient();

	const { width } = useWindowSize();
	const isMobile = width <= ScreenSizes.Small;

	const handleDeleteRequestCompanyModalOpen = (blok) => {
		setIsDeleteRequestCompanyModalOpen(true)
		setSelectedBlok(blok);
	}

	const handleDeleteRequestTalentFromBlokModalOpen = (blok) => {
		setIsDeleteRequestTalentFromBlokModalOpen(true)
		setSelectedBlok(blok);
	}

	const handleRemoveTalentModalOpen = (blok) => {
		setIsRemoveTalentModalOpen(true)
		setSelectedBlok(blok);
	}

	const handleUnsubscribeFromBlokModalOpen = (blok) => {
		setIsUnsubscribeFromBlokModalOpen(true)
		setSelectedBlok(blok);
	}

	const handleDeleteRequestCompany = async () => {
		await blokStore.deleteBlokCompany(selectedBlok!.id)
		setIsDeleteRequestCompanyModalOpen(false)
		await queryClient.invalidateQueries({ queryKey: ['joinedBloks'] })
	}

	const handleDeleteRequestTalentFromBlok = async () => {
		await blokStore.deleteBlokTalent(selectedBlok!.id)
		setIsDeleteRequestTalentFromBlokModalOpen(false)
		await queryClient.invalidateQueries({ queryKey: ['joinedBloks'] })
	}

	const handleRemoveTalent = async () => {
		await blokStore.deleteBlokTalent(selectedBlok!.id)
		setIsRemoveTalentModalOpen(false)
		await queryClient.invalidateQueries({ queryKey: ['joinedBloks'] })
	}

	const handleUnsubscribeFromBlok = async () => {
		await blokStore.deleteBlokCompany(selectedBlok!.id)
		setIsUnsubscribeFromBlokModalOpen(false)
		await queryClient.invalidateQueries({ queryKey: ['joinedBloks'] })
	}

	const handleInviteAccept = async (blok) => {
		await blokPublicStore.subscribe(blok.id, AccountTypes.Talent);
		await queryClient.invalidateQueries({ queryKey: ['joinedBloks'] })
	}

	return (
		<>
			<BaseTableV2 columns={props.columns}>
				{props.bloks.map((blok: IBlokData, index) => (
					<BaseTableV2.Row key={index}>
						<BaseTableV2.Row.Cell>
							<div className={"flex gap-3 items-center"}>
								<FormLogo
									size={LogoSizes.MD}
									inputName={"blokLogo"}
									logoUrl={blok.avatarUrl}
									variant={LogoVariants.Blok}
								/>
								<div className={"flex flex-col max-w-[200px] md:max-w-[450px]"}>
									<Link
										className={"text-gray-700 font-semibold text-base overflow-hidden text-ellipsis whitespace-nowrap"}
										to={PageRoutes.BlokPublicPage.replace(':slug', blok.slug).replace(':tab?', '')}
									>
										{blok.name}
									</Link>
									{blok.isBlokOwnerVisible && (
										<div className={"flex items-center gap-1 text-gray-500 mt-1"}>
											<p>{blok.user.username}</p>
										</div>
									)}
								</div>
							</div>
						</BaseTableV2.Row.Cell>
						{(props.selectedTab === Tabs.Approved && blokStore.isTalentAccountType()) && (
							<BaseTableV2.Row.Cell classes='flex justify-center mt-5'>
								<div className={`${blok.userTotalPoint > 0 ? 'flex' : 'invisible'} gap-1 items-center justify-center max-w-max px-2 bg-neutral-50 text-shade-black text-sm font-semibold py-[2px] rounded-full shadow-md`}>
									<StarIconV3 size={isMobile ? 14 : 21} />
									<span className={`${isMobile ? 'text-[10px]' : 'text-base'} font-semibold`}>
										{__('EventDetailPage.Points').replace('{{count}}', String(blok.userTotalPoint))}
									</span>
								</div>
							</BaseTableV2.Row.Cell>
						)}
						{(props.selectedTab === Tabs.Approved || props.selectedTab === Tabs.Pending || props.selectedTab === Tabs.Invites) && !blokStore.isCompanyOwnerAccountType() &&
							<BaseTableV2.Row.Cell classes={"!whitespace-normal"}>
								<div className={"flex gap-2"}>
									{blokStore.getSkillAndSectors(blok).map((skill: { label: string }) => (
										<Badge type={UIState.Idle} key={skill.label} customClass='whitespace-nowrap inline-flex'>
											{skill.label}
										</Badge>
									))}
								</div>
							</BaseTableV2.Row.Cell>
						}

						{(props.selectedTab === Tabs.Approved || props.selectedTab === Tabs.Pending) && blokStore.isCompanyOwnerAccountType() &&
							<BaseTableV2.Row.Cell classes={"!whitespace-normal"}>
								<div className={"flex gap-2"}>
									{blok.applicationType === RowStates.Pending ? __('BloksPage.NotSubscribed') : <DateDiff date={blok.created} />}
								</div>
							</BaseTableV2.Row.Cell>
						}
						{(props.selectedTab === Tabs.Approved || props.selectedTab === Tabs.Pending) && blokStore.isCompanyOwnerAccountType()  &&
							<BaseTableV2.Row.Cell classes={"!whitespace-normal"}>
								<div className={"flex gap-2"}>
									<Icon name={Icons.Megaphone} color={"text-gray-500"} />
									<Trans i18nKey={"BloksPage.JobPostCount"} values={{ count: blok.jobPostCount ?? 0 }}></Trans>
								</div>
							</BaseTableV2.Row.Cell>
						}
						{
							(props.selectedTab === Tabs.Approved || props.selectedTab === Tabs.Pending) &&
							blokStore.isCompanyOwnerAccountType() && (
								<BaseTableV2.Row.Cell classes={"!whitespace-normal"}>
									<Button
										outlined
										minWidth={false}
										name={"publishButtton"}
										disabled={blok.applicationType === RowStates.Pending || !blok.can_post_job}
										attrs={{ onClick: () => navigate(PageRoutes.JobSummaryStep.replace(':postType', 'blok')) }}
										classes={"!w-[112px] !h-9 !p-0 !text-body-semibold-b6 disabled:text-neutral-400 disabled:bg-white disabled:border-neutral-400 border hover:bg-primary-500 hover:text-white"}
									>
										{__('BloksPage.PublishButton')}
									</Button>
								</BaseTableV2.Row.Cell>
							)
						}

						{(props.selectedTab === Tabs.Approved || props.selectedTab === Tabs.Pending) &&
							<BaseTableV2.Row.Cell>
								<MoreAction name={"JobListItem_MoreAction"}>
									{blok.applicationType === RowStates.Pending && (
										<button
											className={'text-gray-700 flex items-center'}
											onClick={
												blokStore.isCompanyOwnerAccountType()
													? () => handleDeleteRequestCompanyModalOpen(blok)
													: () => handleDeleteRequestTalentFromBlokModalOpen(blok)
											}
										>
											<ReplyIcon />
											{__('BloksPage.RemoveFromPending')}
										</button>
									)}
									{blokStore.isCompanyOwnerAccountType() && blok.applicationType === RowStates.Approved && (
										<button
											className={"text-gray-700 flex items-center"}
											onClick={() => handleUnsubscribeFromBlokModalOpen(blok)}
										>
											<span className='text-error-400'>
												<CrossIcon />
											</span>
											{__("BloksPage.UnsubscribeFromBlok")}
										</button>
									)}
									{!blokStore.isCompanyOwnerAccountType() && blok.applicationType === RowStates.Approved && (
										<button
											className={"text-gray-700 flex items-center"}
											onClick={() => handleRemoveTalentModalOpen(blok)}
										>
											<span className='text-error-400'>
												<CrossIcon />
											</span>
											{__("BloksPage.RemoveFromBlok")}
										</button>
									)}
								</MoreAction>
							</BaseTableV2.Row.Cell>
						}

						{(props.selectedTab === Tabs.Invites) &&
							<BaseTableV2.Row.Cell>
								<MoreAction name={"JobListItem_MoreAction"}>
									{blokStore.isTalentAccountType() && (
										<button onClick={() => handleInviteAccept(blok)} className={"text-gray-700"}>
											{__("BloksPage.JoinToBlok")}
										</button>
									)}
								</MoreAction>
							</BaseTableV2.Row.Cell>
						}
					</BaseTableV2.Row>
				))}
			</BaseTableV2>

			{isDeleteRequestCompanyModalOpen && selectedBlok && <DeleteRequestCompanyFromBlokModal blok={selectedBlok} onClose={() => setIsDeleteRequestCompanyModalOpen(false)} onClick={handleDeleteRequestCompany} />}
			{isDeleteRequestTalentFromBlokModalOpen && selectedBlok && <DeleteRequestTalentFromBlokModal blok={selectedBlok} onClose={() => setIsDeleteRequestTalentFromBlokModalOpen(false)} onClick={handleDeleteRequestTalentFromBlok} />}
			{isRemoveTalentModalOpen && selectedBlok && <RemoveTalentModal blok={selectedBlok} onClose={() => setIsRemoveTalentModalOpen(false)} onClick={handleRemoveTalent} />}
			{isUnsubscribeFromBlokModalOpen && selectedBlok && <UnsubscribeFromBlokModal blok={selectedBlok} onClose={() => setIsUnsubscribeFromBlokModalOpen(false)} onClick={handleUnsubscribeFromBlok} />}
		</>
	);
}
