import BaseStore from '@/js/core/BaseStore';
import { INotification, INotificationStore } from './NotificationTypes';
import NotificationService from '../services/NotificationService';

const notificationStore: INotificationStore = {
	notificationStore: [],
}

class NotificationStore extends BaseStore<INotificationStore, NotificationService> {
	initializeStore() {
		return this.useStore();
	}

	async fetchNotifications(): Promise<INotification[] | undefined> {
		try {
			const result = await this.service.FetchNotifications();
			return result.data.result;
		} catch (error) {
			console.log(error)
		}
	}

	async hasNotifications(): Promise<number | undefined> {
		try {
			const result = await this.service.HasNotifications();
			return result?.data?.result || 0;
		} catch (error) {
			console.log(error)
		}
		return 0;
	}

	async updateNotificationToRead(id: string) {
		try {
			const message = await this.service.UpdateNotificationToRead(id);
			return message.data.result
		} catch (error) {
			console.log(error)
		}
	}

	async updateNotificationsToRead() {
		try {
			const message = await this.service.UpdateNotificationsToRead();
			return message.data.result
		} catch (error) {
			console.log(error)
		}
	}
}

export default new NotificationStore(notificationStore, NotificationService);
