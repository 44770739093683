const StarIconV3 = ({ size = 21 }) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.52444 1.22621C9.88538 0.36893 11.1147 0.368931 11.4756 1.22622L13.6058 6.28606C13.7581 6.64747 14.1021 6.89441 14.497 6.92569L20.0263 7.36362C20.9632 7.43782 21.3431 8.59281 20.6293 9.19685L16.4166 12.7619C16.1157 13.0166 15.9842 13.4162 16.0761 13.7968L17.3632 19.1274C17.5812 20.0305 16.5868 20.7444 15.7847 20.2604L11.0508 17.4038C10.7127 17.1998 10.2874 17.1998 9.94928 17.4038L5.21535 20.2604C4.41328 20.7444 3.41876 20.0305 3.63682 19.1274L4.92391 13.7968C5.01584 13.4162 4.88443 13.0166 4.58353 12.7619L0.370716 9.19685C-0.343055 8.59281 0.0368213 7.43782 0.973658 7.36362L6.50304 6.92569C6.89799 6.89441 7.24203 6.64747 7.39419 6.28606L9.52444 1.22621Z"
				fill="#F4BF00"
			/>
		</svg>
	);
};

export default StarIconV3;
