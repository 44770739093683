import React from 'react';

interface IBellIconProps {
	hasNotifications?: number;
}

const BellIconV3: React.FC<IBellIconProps> = ({ hasNotifications = 0 }) => {
	return (
		<div className="relative w-[44px] h-[44px]">
			<svg
				width="44"
				height="44"
				viewBox="0 0 44 44"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M23.1684 25.2067C25.0629 24.982 26.8898 24.5366 28.6227 23.8969C27.1859 22.302 26.3113 20.1906 26.3113 17.875V17.1742C26.3115 17.1578 26.3115 17.1414 26.3115 17.125C26.3115 13.8113 23.6252 11.125 20.3115 11.125C16.9978 11.125 14.3115 13.8113 14.3115 17.125L14.3113 17.875C14.3113 20.1906 13.4368 22.302 12 23.8969C13.7329 24.5366 15.56 24.982 17.4547 25.2068M23.1684 25.2067C22.2315 25.3178 21.2781 25.375 20.3113 25.375C19.3447 25.375 18.3914 25.3179 17.4547 25.2068M23.1684 25.2067C23.2613 25.4961 23.3115 25.8047 23.3115 26.125C23.3115 27.7819 21.9684 29.125 20.3115 29.125C18.6547 29.125 17.3115 27.7819 17.3115 26.125C17.3115 25.8047 17.3617 25.4962 17.4547 25.2068"
					stroke="#7F8B9F"
					strokeWidth="1.75"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				{hasNotifications > 0 && (
					<>
						<circle cx="27.5" cy="8.9" r="9" fill="#8A47F6" />
						<text
							x="27.5"
							y="8.9"
							fill="white"
							fontSize="9px"
							fontWeight="600"
							textAnchor="middle"
							dominantBaseline="middle"
							style={{ fontFamily: 'Arial, sans-serif' }}
						>
							{hasNotifications > 99 ? '99+' : hasNotifications}
						</text>
					</>
				)}
			</svg>
		</div>
	);
};

export default BellIconV3;
