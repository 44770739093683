import React from 'react';

interface IMessageIconProps {
	hasMessages?: number;
}

const MessageIcon: React.FC<IMessageIconProps> = ({ hasMessages = 0 }) => {
	return (
		<div className="relative w-[44px] h-[44px]">
			<svg
				width="44"
				height="44"
				viewBox="0 0 44 44"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clipPath="url(#clip0_19700_64453)">
					<path
						d="M13.05 18.25H22.05M13.05 21.25H17.55M7.8 22.7593C7.8 24.3604 8.92342 25.754 10.5075 25.987C11.636 26.1529 12.7772 26.2796 13.9299 26.3655C14.28 26.3916 14.6002 26.5753 14.795 26.8674L17.55 31L20.305 26.8675C20.4998 26.5753 20.8199 26.3917 21.1701 26.3656C22.3227 26.2796 23.464 26.153 24.5925 25.9871C26.1766 25.7542 27.3 24.3606 27.3 22.7595V16.7406C27.3 15.1395 26.1766 13.7458 24.5925 13.5129C22.294 13.175 19.9426 13 17.5503 13C15.1578 13 12.8061 13.175 10.5075 13.513C8.92342 13.7459 7.8 15.1396 7.8 16.7406V22.7593Z"
						stroke="#7F8B9F"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
				<defs>
					<clipPath id="clip0_19700_64453">
						<rect width="21" height="21" fill="white" transform="translate(7 11)" />
					</clipPath>
				</defs>
				{hasMessages > 0 && (
					<>
						<circle cx="28.5" cy="8.9" r="9" fill="#8A47F6" />
						<text
							x="28.5"
							y="8.9"
							fill="white"
							fontSize="9px"
							fontWeight="600"
							textAnchor="middle"
							dominantBaseline="middle"
							style={{ fontFamily: 'Arial, sans-serif' }}
						>
							{hasMessages > 99 ? '99+' : hasMessages}
						</text>
					</>
				)}
			</svg>
		</div>
	);
};

export default MessageIcon;
