import { Transition } from '@headlessui/react';
import React, { useEffect, useRef } from 'react';
import { __ } from '@/utils/Trans';
import MoreAction from '@/js/components/MoreAction/MoreAction';
import NotificationCard from './NotificationCard';
import notificationStore from '@/js/modules/notifications/store/NotificationStore';
import { useQuery } from '@tanstack/react-query';

interface INotificationModal extends React.PropsWithChildren {
	isDropdownOpen: boolean;
	setMoreActionsRef?: (ref: React.RefObject<HTMLButtonElement>) => void;
}

const NotificationModal = (props: INotificationModal) => {
	const moreActionsRef = useRef<HTMLButtonElement>(null);

	const handleReadAll = async () => {
		try {
			await notificationStore.updateNotificationsToRead();
		} catch (error) {
			console.error('Error updating notification:', error);
		} finally {
			refetch();
		}
	};

	const { data: notifications, refetch } = useQuery({
		queryKey: ['allNotifications'],
		queryFn: async () => {
			const result = await notificationStore.fetchNotifications();
			return result;
		},
	});

	useEffect(() => {
		if (props.setMoreActionsRef) {
			props.setMoreActionsRef(moreActionsRef);
		}
	}, [props.setMoreActionsRef]);

	return (
		<Transition
			appear
			show={props.isDropdownOpen}
			as={React.Fragment}
			enter={'transition ease-out duration-100'}
			enterFrom={'transform opacity-0 scale-95'}
			enterTo={'transform opacity-100 scale-100'}
			leave={'transition ease-in duration-75'}
			leaveFrom={'transform opacity-100 scale-100'}
			leaveTo={'transform opacity-0 scale-95'}
		>
			<div
				tabIndex={-1}
				className={
					'absolute right-0 z-10 mt-2 origin-top-right w-[364px] p-2.5 bg-white rounded-md shadow ring-1 ring-black ring-opacity-5 focus:outline-none'
				}
			>
				<div className="flex justify-between">
					<p className="text-base text-shade-black font-semibold">
						{__('NotificationModal.Title')}
					</p>
					<MoreAction name={'notificationModal'}>
						<button onClick={handleReadAll} ref={moreActionsRef}>
							{__('NotificationModal.ReadAll')}
						</button>
					</MoreAction>
				</div>
				<div className="bg-[#DADADA] h-[2px] w-full mt-1"></div>
				<div className="max-h-[486px] overflow-scroll">
					{notifications && notifications.length > 0 ? (
						notifications.map((item) => <NotificationCard key={item.id} item={item} />)
					) : (
						<div className="flex justify-center items-center text-base text-neutral-500 h-[180px]">
							{__('NotificationModal.NoNotifications')}
						</div>
					)}
				</div>
			</div>
		</Transition>
	);
};
export default NotificationModal;
