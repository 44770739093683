interface ISelectionBox extends React.PropsWithChildren {
   onClick: () => void;
   isSelected: boolean;
   rounded: boolean;
   disabled?: boolean;
   classes?: string | '';
}

export default function SelectionBox(props: ISelectionBox) {
   return (
      <div
         onClick={props.disabled ? undefined : props.onClick}
         className={`h-[48px] items-center ${props.classes} py-6 px-4 w-full ${props.rounded ? "rounded-full" : "rounded-lg"} ${props.disabled ? "opacity-30" : ""} text-body-regular-b5 border flex gap-[10px] justify-center whitespace-nowrap cursor-pointer ${props.isSelected ? 'bg-primary-50 text-primary-700 border-primary-500' : 'bg-white text-shade-black border-shade-stroke'}`}
      >
         {props.children}
      </div>
   )
}